import authModule from '@/store/auth'
import { Component, Vue, Prop } from 'vue-property-decorator'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import Crm from '@/Models/Crm'
import ResidencyDocument from '@/Models/ResidencyDocument'
import TypeDocumentResidency from '@/Models/TypeDocumentResidency'
import http from '@/services/http'
import * as Helpers from '@/helpers'
import menuModule from '@/store/menu'
@Component({
  components: {
    ValidationObserver,
    ValidationProvider,
  },
})
export default class FormDocument extends Vue {
  @Prop({ required: false, default: null })
  objMember!: Crm
  @Prop({ required: false, default: null })
  objRow!: ResidencyDocument
  @Prop({ required: false, default: null })
  isAction!: string
  selected!: any
  objResidencyDocument: ResidencyDocument = new ResidencyDocument()
  loading = false
  isFileEdit = false
  isDocumentEdit = false
  listDocumentStatus = [
    { id: 1, name: 'Falta', value: 1 },
    { id: 2, name: 'vencido', value: 2 },
    { id: 3, name: 'Presentado', value: 3 },
    { id: 4, name: 'En Archivo', value: 4 },
    { id: 5, name: 'Pendiente', value: 5 },
  ]
  listTypeDocumentResidency: Array<TypeDocumentResidency> = []
  get is_login(): boolean {
    return authModule.isAuthenticated
  }

  async mounted() {
    this.objResidencyDocument.residency_id = parseInt(
      String(menuModule.id_param),
    )
    await this.getTypeDocumentResidency()
    if (this.objRow && this.isAction == 'edit') {
      if (this.objResidencyDocument.document_type_id == undefined) {
        this.selected = this.objRow.document_type.id
      }
      this.$set(this, 'objResidencyDocument', this.objRow)
      this.$set(
        this.objResidencyDocument,
        'document_type_id',
        this.objRow.document_type.document_type,
      )
    }
  }
  async confirmation() {
    const document = this.document(this.objResidencyDocument)
    console.log(document)
    switch (this.isAction) {
      case 'add':
        await this.createResidencyDocument(document)
        break
      case 'edit':
        await this.updateResidencyDocument(document)
        break
      default:
        console.log('ups')
        break
    }
  }
  get filteredDataObj() {
    const filterData = this.listTypeDocumentResidency.filter(
      (option) =>
        option.document_type
          .toString()
          .toLowerCase()
          .indexOf(this.objResidencyDocument.document_type_id) >= 0,
    )
    return filterData
  }
  async createResidencyDocument(objResidencyDocument: any) {
    try {
      await http.post(
        `/api/residency/arcr_residency_documents/`,
        objResidencyDocument,
      )
      this.$buefy.toast.open({
        message: 'Agregado',
        type: 'is-success',
      })
      setTimeout(() => {
        this.loading = false
        this.close()
      }, 1000)
    } catch (error) {
      console.error(error)
      this.loading = false
      this.$buefy.toast.open({
        message: `Ups!, ha ocurrido un error`,
        type: 'is-danger',
      })
    }
  }
  async updateResidencyDocument(objResidencyDocument: any) {
    try {
      await http.put(
        `/api/residency/arcr_residency_documents/${this.objRow.id}/`,
        objResidencyDocument,
      )
      this.$buefy.toast.open({
        message: 'Actualizado',
        type: 'is-success',
      })
      setTimeout(() => {
        this.loading = false
        this.close()
      }, 1000)
    } catch (error: any) {
      this.loading = false
      console.log(error)
    }
  }
  async getTypeDocumentResidency() {
    try {
      const res = await http.get(`api/residency/document_types/`)
      if (!res.data) throw new Error(res.data)
      this.listTypeDocumentResidency = res.data.map((obj: any) => ({
        ...obj,
      }))
    } catch (error) {
      console.error(error)
      this.$buefy.toast.open({
        message: `Ups!, ha ocurrido un error`,
        type: 'is-danger',
      })
    }
  }
  document(objDocument: ResidencyDocument) {
    const formData = new FormData()
    const document = {
      document_description: objDocument.document_description,
      date_presented: Helpers.dateFormat_db(objDocument.date_presented),
      comment: objDocument.comment,
      document_type_id: this.selected.id,
      residency_id: objDocument.residency_id,
      document_status: objDocument.document_status,
    }
    formData.append('document', JSON.stringify(document))
    if (this.isFileEdit) {
      formData.append('file', objDocument.upload)
    }
    return formData
  }
  fileChange() {
    if (this.objResidencyDocument.upload.name) {
      this.isFileEdit = true
    }
  }
  fileDocument() {
    if (this.objResidencyDocument) {
      this.isDocumentEdit = true
    }
  }
  close() {
    this.objResidencyDocument = new ResidencyDocument()
    this.$emit('cleanForm')
  }
}
